<template>
  <v-layout wrap class="justify-center" v-if="model">

    <!-- delete confirmation modal -->
    <v-dialog v-model="deleteModelModal" max-width="500px">
      <v-card>
        <v-card-title>Confirmation Required</v-card-title>
        <v-card-text>
          <p>
            Are you sure want to delete model
            <b>'{{ deletingModel.name }}'</b>?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn text small outlined v-on:click.native="deleteModelModal = false" class="secondary--text">Cancel</v-btn>
          <v-spacer> </v-spacer>
          <v-btn small v-on:click.native="deleteModel(deletingModel)" text class="primary white--text">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="setupServerAuthModal" max-width="800px">
      <v-card>
        <v-card-title>Endpoint settings</v-card-title>

        <v-card-text>
          <!-- <span class="mt-4 text-h6 text--white">
            Basic authentication
          </span> -->
          <p class="caption mt-2">
            Basic authentication is used for servers that expect base64 encoded username and password. This authentication
            method
            is supported by majority of web servers.
          </p>
        </v-card-text>

        <v-card-text>
          <v-layout>
            <v-flex lg6 md6 sm12 class="mr-4">
              <v-text-field v-model="setupServerAuth.username" label="Username" autocomplete="username"></v-text-field>
            </v-flex>

            <v-flex lg6 md6 sm12>
              <v-text-field v-model="setupServerAuth.password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'" label="Password" @click:append="showPassword = !showPassword"
                autocomplete="new-password"></v-text-field>
            </v-flex>

          </v-layout>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="mt-3 ">
          <span class="text-h6 text--white">
            Headers
          </span>
          <p class="caption">
            Headers can be used for authentication. For example, if you are using services like OpenAI or Replicate, add
            headers such as
            Authorization: Token {your_token}
          </p>

        </v-card-text>

        <v-card-actions class="ml-1">
          <v-text-field outlined dense class="mr-2 mt-4" v-model="newHeader.key" label="Header"></v-text-field>
          <v-text-field outlined dense class="mt-4" v-model="newHeader.value" label="Value"></v-text-field>

          <v-btn text icon color="primary" @click="addHeaderToList()">
            <v-icon class="mb-1">add_box</v-icon>
          </v-btn>
        </v-card-actions>

        <v-list class="ml-2" v-if="this.setupServerAuth.headers && Object.keys(this.setupServerAuth.headers).length > 0">
          <v-list-item v-for="(value, name) in this.setupServerAuth.headers" :key="name">
            <v-list-item-content>
              <v-list-item-title>
                <strong>{{ name }}</strong>: {{ value }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon ripple @click="deleteHeaderFromList(name)">
                <v-icon small color="grey lighten-1">delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-card-text class="mt-3">
          <v-layout>
            <v-flex lg6 md6 sm12 class="mr-4">
              <span class="mb-2 text-h6 text--white">
                Concurrency
              </span>
              <p class="caption">
                How many concurrent requests can this endpoint handle?
              </p>
            </v-flex>
            <v-flex lg6 md6 sm12 class="mr-4">
              <v-text-field v-model="setupServerAuth.concurrency" type="number"></v-text-field>
            </v-flex>
          </v-layout>

        </v-card-text>

        <v-card-actions>
          <v-btn text small outlined v-on:click.native="setupServerAuthModal = false"
            class="secondary--text">Cancel</v-btn>
          <v-spacer> </v-spacer>
          <v-btn small v-on:click.native="saveServerSettings()" text class="primary white--text">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-flex lg12 md12 sm12 pr-2>
      <v-container container--fluid>
        <v-layout>
          <v-breadcrumbs
            style="padding-left: 0px; padding-top: 5px; padding-bottom: 5px;"
            :items="breadcrumbs"
            divider="/"
          ></v-breadcrumbs>
        </v-layout>
        <v-layout>
          <page-title>Edit model '{{ model.name }}'</page-title>

          <div class="ml-4 mt-1 mb-2">
            <v-btn class="secondary--text mt-2 mr-3" text small @click="deleteModelIntent()">
              <v-icon small class="mr-1">mdi-delete</v-icon>
              Delete
            </v-btn>

            <v-btn class="secondary--text mt-2 mr-3" text small router :to="{
                name: 'modelLogs',
                params: {
                  modelId: model.id,
                },
              }">
              <v-icon small class="mr-1">mdi-history</v-icon>
              Logs
            </v-btn>
          </div>
        </v-layout>

        <v-layout class="mt-4">
          <p class="secondary--text caption">
            View and manage servers that run your model. Meteron will automatically distribute the load across all the
            endpoints in this model, ensure concurrency and enforce limits.
          </p>
        </v-layout>

        <v-layout class="mt-2" wrap v-if="model">
          <v-flex lg4 md4 sm12>
            <!-- <div class="tbcard"> -->                          
              <v-card flat class="mb-2" height="220">
                <v-card-title class="secondary--text text-subtitle-1" style="padding-bottom: 0px;padding-top: 5px;">
                
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <span v-on="{ ...tooltip }">
                      User usage limits
                      </span>
                    </template>
                    Limits are calculated using the X-User header. Once limits are enabled,
                    this header is required. Set to zero for unlimited.
                  </v-tooltip>
                  <v-spacer></v-spacer>
                  <v-switch class="" v-model="timeLimitsEnabled"></v-switch>
                </v-card-title>
                
                <v-card-text>
                  <v-list-item v-if="timeLimitsEnabled" dense style="padding-left: 0px; padding-right: 0px;">
                    <v-list-item-content class="mr-3">
                      <v-text-field dense type="number" persistent-hint hint="Requests per day" v-model="timeLimitsPerDay" outlined height="5px"></v-text-field>
                    </v-list-item-content>

                    <v-list-item-content class="">
                      <v-text-field dense type="number" persistent-hint hint="Requests per monthly"  v-model="timeLimitsPerMonth" outlined></v-text-field>
                    </v-list-item-content>
                  </v-list-item>

                  <p v-if="!timeLimitsEnabled" class="text-caption">
                    Limits per user. Control how many requests
                    your users can make daily or monthly. Set to zero for unlimited. Perfect when paired with credits.
                  </p>

                </v-card-text>

                <!-- align card-actions to the bottom -->
                <v-card-actions class="align-end" >
                  <v-spacer> </v-spacer>
                  <!-- <v-btn small v-on:click.native="saveModel()" text class="primary white--text mr-3">
                    Save
                  </v-btn> -->
                  <v-btn 
                    small 
                    :disabled="!timeLimitRuleChanged"
                    v-on:click.native="saveModel()" 
                    text 
                    :class="timeLimitRuleChanged ? `primary white--text mr-3`:  `secondary--text`">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
           
          </v-flex>

          <v-flex lg4 md4 sm12>
            <!-- <div class="tbcard"> -->
              <v-card v-if="model" flat class="mb-2 ml-2" height="220">
                <v-card-title class="secondary--text text-subtitle-1" style="padding-bottom: 0px;padding-top: 5px;">Priority class                
                  <span v-if="model.modelType === 'text'" class="caption ml-1 text--primary">(only on image models)</span>
                  <v-spacer></v-spacer>
                  <v-switch class="" :disabled="model.modelType === 'text'"  v-model="priorityEnabled"></v-switch>
                </v-card-title>
                                
                <v-card-text>
                  <!-- <v-list dense tile>
                    <v-list-item v-if="project && priorityEnabled" dense>
                      <v-list-item-content class="mr-3">
                        <v-text-field dense type="number" persistent-hint hint="Medium priority" v-model="priorityMediumThreshold" outlined height="5px"></v-text-field>
                      </v-list-item-content>

                      <v-list-item-content class="">
                        <v-text-field dense type="number" persistent-hint hint="Monthly"  v-model="priorityLowThreshold" outlined></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list> -->

                  <p class="text-caption">
                    Send X-Priority and X-User headers, where priority is "high", "medium" or "low". High
                    priority requests will be served first.
                  </p>

                </v-card-text>

                <v-card-actions>
                  <v-spacer> </v-spacer>
                  <v-btn 
                    small 
                    :disabled="priorityEnabled === model.rules.priority" 
                    v-on:click.native="saveModel()" 
                    text 
                    :class="priorityEnabled === model.rules.priority ? `secondary--text` : `primary white--text mr-3`">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
           
          </v-flex>

          <v-flex lg4 md4 sm12>           
              <v-card flat class="mb-2 ml-2" height="220">
                <v-card-title class="secondary--text text-subtitle-1" style="padding-bottom: 0px;padding-top: 5px;">
                
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <span v-on="{ ...tooltip }">
                        Monetization
                      </span>                      
                    </template>
                    Usage is calculated using the X-User headers. Model response
                    must be compatible with Ollama or OpenAI APIs.
                  </v-tooltip>
                
                
                  <span class="caption ml-2 text--primary"></span>
                  <v-spacer></v-spacer>
                  <v-switch v-model="creditLimitsEnabled"></v-switch>
                </v-card-title>
                
                <v-card-text>

                  <v-list-item v-if="creditLimitsEnabled" dense style="padding-left: 0px; padding-right: 0px;">
                    <v-list-item-content class="mr-3" :style="model.modelType === 'image' ? 'max-width: 50%;' : ''">
                      <v-text-field dense type="number" persistent-hint hint="Cost per request"  v-model="costPerRequest" outlined></v-text-field>
                    </v-list-item-content>

                    <v-list-item-content v-if="model.modelType === 'text'" class="" >
                      <v-text-field dense type="number" persistent-hint hint="Cost per token" v-model="costPerToken" outlined height="5px"></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                  
                  <div v-if="!creditLimitsEnabled">
                    <p class="text-caption" v-if="model.modelType ==='text'">
                      Monetize your LLMs. Set a credits cost per token for this model. All requests
                      will be charged to the user's account, user top-ups will be required.
                    </p>

                    <p class="text-caption" v-else>
                      Monetize your AI models. Set a credits cost per request for this model. All requests
                      will be charged to the user's account, user top-ups will be required.
                    </p>
                  </div>
                
                </v-card-text>

                <v-card-actions>
                  <v-spacer> </v-spacer>
                  <v-btn 
                    small 
                    :disabled="!creditsRuleChanged"
                    v-on:click.native="saveModel()" 
                    text 
                    :class="creditsRuleChanged ? `primary white--text mr-3`:  `secondary--text`"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>            
          </v-flex>
        </v-layout>


        <v-layout class="mt-2" wrap>
          <v-flex lg12 md12 sm12 class="pb-4">             
                <v-card flat>
                  <v-data-table :key="tableKey" :headers="headers" :items="servers" :search="e3" :loading="loading"
                    loading-text="Loading... Please wait">

                    <template v-slot:top>
                    <v-row>
                      <v-layout wrap class="justify-end mt-5 mb-5">
                        <v-spacer></v-spacer>
                          <div class="mr-5">
                            <add-server :model="model"></add-server>
                          </div>                                                  
                      </v-layout>
                    </v-row>
                  </template>


                    <template v-slot:[`item.createdAt`]="{ item }">
                      <v-tooltip top :key="`del-${item.createdAt}`">
                        <template v-slot:activator="{ on: tooltip }">
                          <span v-on="{ ...tooltip }">
                            {{ item.createdAt | ago }}
                          </span>
                        </template>
                        <span>{{ item.createdAt | date }}</span>
                      </v-tooltip>
                    </template>

                    <template v-slot:[`item.url`]="{ item }">
                      <span>{{ item.url }}</span>
                    </template>

                    <template v-slot:[`item.status`]="{ item }">
                      <v-tooltip top :key="`del-${item.id}`">
                        <template v-slot:activator="{ on: tooltip }">
                          <span v-on="{ ...tooltip }">
                            <v-icon v-if="item.alive" small color="green accent-3">fiber_manual_record</v-icon>
                            <v-icon v-else small color="error">
                              fiber_manual_record
                            </v-icon>
                          </span>
                        </template>
                        <span v-if="item.alive">Server is reachable</span>
                        <span v-else>Server is down</span>
                      </v-tooltip>
                    </template>

                    <template v-slot:[`item.settings`]="{ item }">
                      <v-btn small text @click="setupServerSettingsIntent(item)" class="secondary--text">
                        <v-icon small class="mr-1">mdi-cog</v-icon>
                      </v-btn>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                      <v-menu class="items" top left transition="v-slide-y-transition">
                        <template v-slot:activator="{ on: on }">
                          <v-btn icon v-on="on" :class="
                            $store.state.theme === 'light' ? 'secondary--text' : ''
                          ">
                            <v-icon>more_vert</v-icon>
                          </v-btn>
                        </template>

                        <v-list dense>
                          <v-list-item @click="deleteServer(item)">
                            <v-list-item-title>delete</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </template>
                  </v-data-table>
                </v-card>
              
            <!-- </div> -->
          </v-flex>

        </v-layout>

        <v-layout class="mt-2" wrap>
          <v-flex lg12 md12 sm12 class="pb-4">
            <!-- <div class="tbcard"> -->             
                <v-card flat>
                  <v-card-actions v-if="project && project.apiToken !== '' && model">
                    <image-request-generator v-if="model.modelType === 'image'" :model="model" :apiToken="project.apiToken"></image-request-generator>
                    <text-request-generator v-if="model.modelType === 'text'" :model="model" :apiToken="project.apiToken"></text-request-generator>
                  </v-card-actions>
                </v-card>
          </v-flex>
        </v-layout>

      </v-container>
    </v-flex>
  </v-layout>
</template>

<style lang="stylus">
.tbcard {
  width: 100%;
  display: block;
}
</style>

<script>
import PageTitle from '../PageTitle';
import AddServer from './AddServer';
import TextRequestGenerator from '../images/TextRequestGenerator.vue';
import ImageRequestGenerator from '../images/ImageRequestGenerator.vue';

export default {
  components: {
    AddServer,
    PageTitle,
    TextRequestGenerator,
    ImageRequestGenerator
  },
  data() {
    return {      
      tableKey: '',
      e3: null,
      headers: [
        // { text: 'Added', align: 'left', value: 'createdAt', sortable: true },
        { text: 'Endpoint URL', value: 'url', align: 'left' },
        { text: 'Concurrency', value: 'concurrency', align: 'center' },
        { text: 'Status', value: 'status', align: 'center' },
        { text: 'Settings', value: 'settings', align: 'center' },
        { text: '', value: 'actions', sortable: false, align: 'right' }
      ],
      modal: false,
      servers: [],

      deleteModelModal: false,
      deletingModel: {
        id: '',
        projectId: '',
      },

      setupServerAuthModal: false,
      setupServerAuth: {
        id: '',
        username: '',
        password: '',
        headers: null,
        concurrency: 1,
      },
      newHeader: {
        key: '',
        value: ''
      },
      showPassword: false,

      timeLimitsEnabled: false,
      timeLimitsPerDay: 10,
      timeLimitsPerMonth: 100,

      creditLimitsEnabled: false,
      costPerToken: 0.0,
      costPerRequest: 0.0,

      priorityEnabled: false,
      priorityMediumThreshold: 20,
      priorityLowThreshold: 5
    }
  },

  computed: {
    modelId() {
      return this.$route.params.modelId
    },
    project() {
      return this.$store.state.user.user?.projects[0]
    },
    loading() {
      return this.$store.state.model.loading
    },
    error() {
      return this.$store.state.model.error
    },
    model() {
      return this.$store.state.model.model
    },
    timeLimitRuleChanged() {
      if (typeof this.model === 'undefined') {
        return true
      }

      if (this.timeLimitsEnabled !== this.model.rules.timeBasedLimits) {
        return true
      }

      if (this.timeLimitsPerDay !== this.model.rules.timeBasedLimitsConfig.maxRequestsPerDay) {
        return true
      }

      if (this.timeLimitsPerMonth !== this.model.rules.timeBasedLimitsConfig.maxRequestsPer30Days) {
        return true
      }

      return false
    },
    creditsRuleChanged() {
      if (typeof this.model === 'undefined') {
        return true
      }

      if (this.creditLimitsEnabled !== this.model.rules.credits) {
        return true
      }

      if (this.costPerToken !== this.model.rules.creditsConfig.costPerToken) {
        return true
      }

      if (this.costPerRequest !== this.model.rules.creditsConfig.costPerRequest) {
        return true
      }

      return false
    },
    breadcrumbs() {
      if (!this.model) {
        return []
      }
      return [
        {
          text: 'Home',
          disabled: false,
          to: '/'
          // }
          // href: '/',
          // to: {
          //   name: 'modelDetails',
          //   params: {
          //     modelId: model.id,
          //   },
          // }
        },
        {
          text: this.model.name,
          disabled: true,          
        },        
      ]
    }
  },

  watch: {
    model() {
      // Copy the servers array      
      this.servers = this.model.servers.slice(0)


      this.$nextTick(() => {
        this.tableKey = Date.now()
      })
    }
  },

  mounted() {
    this.refresh()
  },

  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = undefined
    }
  },

  methods: {
    async refresh() {
      // Getting the user so we get the project
      await this.$store.dispatch('GetUser')
      await this.fetchCluster()
    },

    async fetchCluster() {
      // Getting the model
      const q = {
        projectId: this.project.id,
        modelId: this.modelId
      }
      await this.$store.dispatch('GetModel', q)

      this.priorityEnabled = this.model.rules.priority

      this.timeLimitsEnabled = this.model.rules.timeBasedLimits
      this.timeLimitsPerDay = this.model.rules.timeBasedLimitsConfig.maxRequestsPerDay
      this.timeLimitsPerMonth = this.model.rules.timeBasedLimitsConfig.maxRequestsPer30Days
    
      this.creditLimitsEnabled = this.model.rules.credits
      this.costPerToken = this.model.rules.creditsConfig.costPerToken
      this.costPerRequest = this.model.rules.creditsConfig.costPerRequest
    },

    async deleteServer(item) {
      this.servers.splice(this.servers.indexOf(item), 1)
      await this.saveModel()
    },

    async saveServerSettings() {
      let server = this.servers.find(s => s.id === this.setupServerAuth.id)
      let serverIndex = this.servers.findIndex(s => s.id === this.setupServerAuth.id)

      let serverCopy = Object.assign({}, server)
      // Set the username and password
      serverCopy.config.username = this.setupServerAuth.username
      serverCopy.config.password = this.setupServerAuth.password
      serverCopy.config.headers = this.setupServerAuth.headers
      serverCopy.concurrency = parseInt(this.setupServerAuth.concurrency)

      this.servers[serverIndex] = serverCopy

      // Save it
      await this.saveModel()

      this.setupServerAuthModal = false
    },

    async saveModel() {
      let payload = {
        name: this.model.name,
        projectId: this.project.id,
        servers: this.servers,
        rules: {
          timeBasedLimits: this.timeLimitsEnabled,
          timeBasedLimitsConfig: {
            maxRequestsPerDay: parseInt(this.timeLimitsPerDay),
            maxRequestsPer30Days: parseInt(this.timeLimitsPerMonth)
          },
          credits: this.creditLimitsEnabled,
          creditsConfig: {
            costPerToken: parseFloat(this.costPerToken),
            costPerRequest: parseFloat(this.costPerRequest)
          },
          priority: this.priorityEnabled
        }
      }

      await this.$store.dispatch('SetModel', payload)
      if (this.error === null) {
        this.refresh()
      } else {
        this.$store.dispatch('Notify', this.error)
      }
    },

    setupServerSettingsIntent(item) {
      // assigning removing input val
      this.setupServerAuth = {
        id: item.id,
        username: item.config.username,
        password: item.config.password,
        headers: item.config.headers,
        concurrency: item.concurrency,
      }

      if (!this.setupServerAuth.headers) {
        this.setupServerAuth.headers = {}
      }

      this.setupServerAuthModal = true
    },

    addHeaderToList() {
      if (this.newHeader.key === '' || this.newHeader.value === '') {
        // nothing to do
        return
      }
      this.setupServerAuth.headers[this.newHeader.key] = this.newHeader.value

      // reset it
      this.newHeader.key = ''
      this.newHeader.value = ''
    },
    deleteHeaderFromList(key) {
      this.$delete(this.setupServerAuth.headers, key)
    },

    deleteModelIntent() {
      // assigning removing input val
      this.deletingModel = Object.assign({}, this.model)
      this.deleteModelModal = true
    },

    async deleteModel(model) {
      await this.$store.dispatch('DeleteModel', model)
      this.deleteModelModal = false
      // Navigate back to home
      this.$router.push({
        name: 'home'
      })
    }
  }
}
</script>
